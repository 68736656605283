import { Domanda, IDomanda } from './Domanda';

export interface IGestioneOsservazionePrimiGiorniDiAmbientamento {
  periodoOsservazioneAl: Date;
  periodoOsservazioneDal: Date;

  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class GestioneOsservazionePrimiGiorniDiAmbientamento implements IGestioneOsservazionePrimiGiorniDiAmbientamento {
  periodoOsservazioneAl: Date;
  periodoOsservazioneDal: Date;

  /**
   * Relazioni
   */
  domande: Domanda[];

  constructor(params: Partial<IGestioneOsservazionePrimiGiorniDiAmbientamento> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.periodoOsservazioneAl) {
      this.periodoOsservazioneAl = new Date(params.periodoOsservazioneAl);
    }
    if (params.periodoOsservazioneDal) {
      this.periodoOsservazioneDal = new Date(params.periodoOsservazioneDal);
    }

    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }

  static InitContent(): GestioneOsservazionePrimiGiorniDiAmbientamento {
    const content = new GestioneOsservazionePrimiGiorniDiAmbientamento();

    const testiDomande = [
      // Sezione "Saluto e accoglienza"
      'Come avviene il saluto?',
      'Varia a seconda di chi lo/la accompagna?',
      'Il bambino come accoglie le strategie messe in atto dall’insegnante e dai genitori/famiglia?',

      // Sezione "Le relazioni"
      'Quali sono gli spazi ed i materiali che interessano ed incuriosiscono maggiormente il/la bambino/a?',
      'Come si pone in relazione con gli altri bambini?',
      'Come si pone in relazione agli adulti?',

      // Sezione "Le ritualità nel quotidiano"
      'Come vive il momento del cambio?',
      'Come vive il momento del pranzo?',
      'Come vive il momento del sonno? Con quali rituali?',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `GestioneOsservazionePrimiGiorniDiAmbientamento_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IGestioneOsservazionePrimiGiorniDiAmbientamento {
    return Object.assign({}, this);
  }
}
